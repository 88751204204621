@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';

.content-card {
  @extend .align-center;
  margin-top:em(30px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.boxed-content {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  background-color: rgba(255,255,255,0.9);
  border: none;
  border-radius: em(50px);
  z-index: 9;
  @include for-mobile-layout {
    width: 100%;
    padding-top: em(30px);
    padding-bottom: em(30px);
    padding-left: em(25px);
    padding-right: em(7px);
  }
  @include for-narrow-layout {
    width: em(650px);
    padding: em(55px);
  }
  @include for-normal-layout {
    width: em(950px);
    padding: em(80px);
  }

  h2 {
    text-transform: capitalize;
    font-family: "Lilita-One-regular";
    font-size: em(30px);
  }

  h3 {
    font-family: "Titillium-Web";
    font-size: em(25px);
  }

  span {
    color: gray;
    margin-bottom: em(50px);
  }
}

.white-text {
  color: $clr3;
}
