// Font Sizes

.fs12 {
  font-size: rem(12px);
}
.fs14 {
  font-size: rem(14px);
}
.fs18 {
  font-size: rem(18px);
}
.fs20 {
  font-size: rem(20px);
}
.fs24 {
  font-size: rem(24px);
}
.fs28 {
  font-size: rem(28px);
}
.fs30 {
  font-size: rem(30px);
}

.fs40 {
  font-size: rem(40px);
}

.caps {
  text-transform: uppercase;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.clean-list {
  list-style: none;
}

.both-align-center {
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.big-btn {
  padding: 10px;
}

.underLine {
  text-decoration: underline;
}
