@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';
@import '../../styles//core//buttons';

.lds-ripple {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -1.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.game-result {
  padding-top: em(20px);

  .game-summery {
    @extend .flex;
    @extend .justify-center;
  }

  .score-label {
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
    @extend .column;
    
    color: $clr3;
    font-family: "Lilita-One-regular";
    font-size: em(29px);
    margin: em(20px);
    p {
      font-size: em(16px);
      text-align: center;
      padding: 0;
      color: $clr2;
      margin: 0;
    }
    span {
      font-size: em(30px);
      padding: 0;
    }
  }

  .game-summery-msg {
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
  
    h2 {
      color: white;
      font-family: "Lilita-One-regular";
      font-size: em(30px);
      margin-bottom: em(20px);
    }
  }

  .continue {
    @extend .fixed-btn;
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
  
    button {
      @extend .white-btn;
    }
  }
}
