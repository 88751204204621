@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';

.quiz-card {
  @extend .flex;
  @extend .align-center;
  @extend .justify-center;
  @extend .align-center;
  @extend .layout-container;
  
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .quiz-img {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    filter: brightness(50%);
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
  }
}

.boxed-content {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  background-color: rgba(255,255,255,0.9);
  border: none;
  border-radius: em(50px);
  z-index: 9;
  @include for-mobile-layout {
    padding-top: em(30px);
    padding-bottom: em(30px);
    width: 100%;
  }
  @include for-narrow-layout {
    width: em(600px);
    padding: em(55px);
  }
  @include for-normal-layout {
    width: em(700px);
    padding: em(80px);
  }

  h2{
    text-transform: capitalize;
    font-family: "Lilita-One-regular";
    font-size: em(45px);

    @include for-mobile-layout {
      font-size: em(35px);
    }
  }

  span {
    // color: gray;
    margin-bottom: em(50px);
  }
}

.ctpro-btn {
  padding: em(20px);
  border-radius: em(300px);
  color: $clr3;
  letter-spacing: em(1px);
  min-width: em(200px);
  font-size:em(20px);
  margin:em(5px);
  border:none;
  outline: none;
  font-family: "Lilita-One-regular";
  background-color: $clr1;
  cursor: pointer;
}

.hide {
  display: none;
}

.back-btn {
  width: em(60px);
  height: em(60px);
  padding: em(5px);
  fill: $clr3;
  background-color: rgba(255,255,255,0.0);
  border:none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top:em(20px);
  left: em(30px);

}
