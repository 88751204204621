@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';

.lobby-card {
  @extend .flex;
  @extend .align-center;
  @extend .justify-center;
  @extend .align-center;
  @extend .layout-container;
  
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.boxed-content {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  background-color: rgba(255,255,255,0.9);
  border: none;
  border-radius: em(50px);
  z-index: 9;
  @include for-mobile-layout {
    padding-top: em(30px);
    padding-bottom: em(30px);
    width: 100%;
  }
  @include for-narrow-layout {
    width: em(600px);
    padding: em(55px);
  }
  @include for-normal-layout {
    width: em(700px);
    padding: em(80px);
  }

  h2{
    text-transform: capitalize;
    font-family: "Lilita-One-regular";
    font-size: em(45px);
  }

  span {
    color: gray;
    margin-bottom: em(50px);
  }
}

.timer {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  .heading{
    font-size: em(70px);
    font-family: "Lilita-One-regular";
    margin-bottom: em(15px);
    color: $clr1;
  
    @include for-mobile-layout {
      margin-bottom: em(5px);
    }
    @include for-narrow-layout {
  
    }
    @include for-normal-layout {
      margin-bottom: em(15px);
    }
  }
}

.status {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  .joined-headline {
    font-size: em(30px);
    font-family: "Lilita-One-regular";
  }

  .join-list {
    // display: grid;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // grid-template-columns: 1fr ;
    font-size: em(24px);
    // gap: em(10px);
    width: em(200px);
    line-height: em(23px);
  }

  .player-name {
    display: block;
    color: black;
    font-family: "Titillium-Web", sans-serif;
    font-size: em(16px);
  }

  .player-name:not(:last-child):after {
    content: ', ';
    white-space: pre;
  }
}

.ctpro-btn {
  padding: em(20px);
  border-radius: em(300px);
  color: $clr3;
  letter-spacing: em(1px);
  min-width: em(200px);
  font-size:em(20px);
  margin:em(5px);
  border:none;
  outline: none;
  font-family: "Lilita-One-regular";
  background-color: $clr1;
  cursor: pointer;
}

.hide {
  display: none;
}

.lds-ripple {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -1.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
