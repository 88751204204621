@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';
@import '../../styles//core//buttons';

.game-result {
  padding-top: em(20px);
}

.winners-podium-trophy {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;

  margin-bottom: em(20px);
}

.winners-podium {
  @extend .flex;
  @extend .justify-center;

  align-items: flex-end;
  .place {
    font-family: "Lilita-One-regular";
    background-color: white;
    border-radius: 100%;
    position: relative;
    @include for-mobile-layout {
      margin: em(9px);
    }
    @include for-narrow-layout {
      margin: em(9px);
    }
    @include for-normal-layout {
    }
    margin: em(20px);
    span {
      border-radius: 100%;
      background-color: $clr2;
      position: absolute;
      color: $clr3;
      @include for-mobile-layout {
      }
      @include for-narrow-layout {
      }
      @include for-normal-layout {
      }
    }
    div {
      @include for-mobile-layout {
        font-size: em(20px);
      }
      @include for-narrow-layout {
      }
      @include for-normal-layout {
      }
    }
    .player-name {
      @extend .center;
      font-size: em(20px);
    }
    .player-score {
      font-size: em(50px);
      @include for-mobile-layout {
        font-size: em(30px);
      }
      @include for-narrow-layout {
      }
      @include for-normal-layout {
      }
    }
  }
}

.place-3rd {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  width: em(160px);
  height: em(160px);
  @include for-mobile-layout {
    width: em(100px);
    height: em(100px);
  }
  @include for-narrow-layout {
    width: em(140px);
    height: em(140px);
  }
  @include for-normal-layout {
  }
  span {
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;

    height: em(45px);
    width: em(45px);
    top: em(-15px);
    @include for-mobile-layout {
      height: em(30px);
      width: em(30px);
    }
    @include for-narrow-layout {
      height: em(45px);
      width: em(45px);
    }
    @include for-normal-layout {
      height: em(45px);
      width: em(45px);
    }
  }
}

.place-2nd {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  width: em(160px);
  height: em(160px);
  @include for-mobile-layout {
    width: em(100px);
    height: em(100px);
  }
  @include for-narrow-layout {
    width: em(140px);
    height: em(140px);
  }
  @include for-normal-layout {
  }
  span {
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;

    height: em(45px);
    width: em(45px);
    top: em(-15px);
    @include for-mobile-layout {
      height: em(30px);
      width: em(30px);
    }
    @include for-narrow-layout {
      height: em(45px);
      width: em(45px);
    }
    @include for-normal-layout {
      height: em(45px);
      width: em(45px);
    }
  }
}

.place-1st {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  width: em(200px);
  height: em(200px);
  color: $clr2;
  @include for-mobile-layout {
    width: em(160px);
    height: em(160px);
  }
  @include for-narrow-layout {
    width: em(180px);
    height: em(180px);
  }
  @include for-normal-layout {
  }
  span {
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
    
    height: em(50px);
    width: em(50px);
    top: em(-20px);
  }
}

.score-label {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;
  
  color: $clr3;
  font-family: "Lilita-One-regular";
  font-size: em(29px);
  margin: em(20px);
  p {
    font-size: em(16px);
    text-align: center;
    padding: 0;
    color: $clr2;
    margin: 0;
  }
  span {
    font-size: em(30px);
    padding: 0;
  }
}

.game-summery {
  @extend .flex;
  @extend .justify-center;
}

.game-summery-msg {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;

  h2 {
    color: white;
    font-family: "Lilita-One-regular";
    font-size: em(30px);
    margin-bottom: em(20px);
  }
}

.continue {
  @extend .fixed-btn;
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;

  button {
    @extend .white-btn;
  }
}
