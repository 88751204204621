.small-btn {
  width: rem(39px);
  height: rem(39px);
  background-color: lightgray;
}
.mid-btn {
  width: rem(180px);
  height: rem(37px);
  background-color: $clr2;
  color: $clr3;
  margin-bottom: rem(11px);
  font-size: rem(19px);
  border: none;

  border-radius: rem(13px);
}
.quiz-details-like-btn {
  width: rem(30px);
  height: rem(30px);
  background-color: lightgray;
}

.pink-btn {
  background-color: $clrbtn2;
  border: none;
  font-size: rem(16px);
  color: white;
  padding: em(16px);
  width: em(200px);
  border-radius: em(10px);
  outline: none;
  box-shadow: 0 0 5px 0px black;
  margin: em(5px);
}

.white-btn {
  padding: 10px;
  font-size: 30px;
  border: none;
  border-radius: 20px;
  background-color: white;
  color: #fdba21;
  font-family: "Lilita-One-regular";
  cursor: pointer;
}

.ctpro-btn{
  padding: em(20px);
  border-radius: em(300px);
  color: $clr3;
  letter-spacing: em(1px);
  min-width: em(200px);
  font-size:em(20px);
  margin:em(5px);
  border:none;
  outline: none;
  font-family: "Lilita-One-regular";
  background-color: $clr1;
  cursor: pointer;
}

.ctpro-input{
  border-top:none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  text-align: center;
  background-color: rgba(0,0,0,0.0);
  font-family: "Titillium-Web",sans-serif;
  font-size: em(25px);
  padding: 0;
  outline: none;
  // border-bottom: 3.5px solid $clr1;
}
.line{
  height: em(3.5px);
  background: $clr1;
  border-top-right-radius: em(100px);
  border-top-left-radius: em(100px);
  border-bottom-right-radius: em(100px);
  border-bottom-left-radius: em(100px);
}

.input-name-container{
  margin-bottom: em(60px);
}

.fixed-btn{
  position: fixed;
  bottom: em(20px);
  left:0;
  right:0;
}
input {
  padding: 10px;
}


.app-input{
  outline: none;
  // border: none;
  border-top: none;
  border-right:none;
  border-left:none;
  margin-top: em(50px);
  margin-bottom: em(50px);
  border-bottom-color: 1px solid white;
  background-color: rgba(0,0,0,0.0);
  color:white;
  font-size: em(16px);
}
.app-input::placeholder{
  color: white;
}
