@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';

.homepage {
  @extend .layout-container;

  .white-headline {
    color: white;
    // margin-bottom: em(5px);
    font-family: "Lilita-One-regular", sans-serif;
    letter-spacing: em(1px);
  }

  .orange-headline{
    color: $clr2;
    font-family: "Titillium-Web", sans-serif;
  }

  .margin-fix {
    margin-top: em(20px);
  }
  
  .margin-fix-bottom {
    margin-bottom:em(10px);
  }
  .margin-fix-top {
    margin-top:em(30px);
  }


  .quiz-list-container {
    display: grid;
    // margin-bottom:em(100px);
    @include for-mobile-layout {
      grid-template-columns: 1fr;
      gap: 10px;
    }
    @include for-narrow-layout {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    @include for-normal-layout {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
  }


  .quiz-preview-card {
    padding: rem(8px);
    position: relative;
    background-color: $clr3;
    border-radius: rem(10px);
    display: flex;
    cursor: pointer;
    transition: all ease-in-out .3s;
    @include for-mobile-layout {
      flex-direction: row;
    }
    @include for-normal-layout {
      flex-direction: column;
    }
    @include for-narrow-layout {
      flex-direction: column;
    }
  }

  .quiz-preview-card:hover {
    transform: scale(1.01);
  }

  .quiz-preview-card p {
    padding: 0;
    margin: 0;
  }

  .live-badge {
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;

    position:absolute;
    border-radius: em(300px);
    width: em(50px);
    height: em(50px);
    z-index: 9;
    color: white;
    top:-20px;
    right: -20px;
    font-family: "Lilita-One-regular", sans-serif;
    background-color: $clr2;
  }

  .pulse {
    box-shadow: 0 0 0 rgba(204,169,44, 0.9);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  .quiz-preview-img-container {
    height: rem(190px);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom:em(5px);
    background-position: bottom;
    border-radius: rem(10px);
    @include for-normal-layout {
  
    }
    @include for-narrow-layout {
  
    }
    @include for-mobile-layout {
      width: 100%;
      margin-right:10px;
    }
  }

  .quiz-preview-details {
    width: 100%;
    font-family: "Titillium-Web";
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .quiz-name{
    text-transform: capitalize;
  
    margin-top: -2px;
    margin-bottom: 10px;
  }

  .quiz-preview-timer {
    @extend .flex;
    @extend .space-between;
    @extend .align-center;
    text-align: right;
  }

  .quiz-preview-timer span {
    font-size: rem(40px);
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
}

.home-card {
  @extend .align-center;
  margin-top:em(30px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.boxed-content {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  background-color: rgba(255,255,255,0.9);
  border: none;
  border-radius: em(50px);
  z-index: 9;
  @include for-mobile-layout {
    padding-top: em(30px);
    padding-bottom: em(30px);
    width: 100%;
  }
  @include for-narrow-layout {
    width: em(600px);
    padding: em(55px);
  }
  @include for-normal-layout {
    width: em(700px);
    padding: em(80px);
  }

  h2{
    text-transform: capitalize;
    font-family: "Lilita-One-regular";
    font-size: em(45px);
  }

  span {
    color: gray;
    margin-bottom: em(50px);
  }
}

.white-text {
  color: $clr3;
}
