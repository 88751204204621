@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';
@import '../../styles/core/buttons';

.score-table {
  @extend .flex;
  @extend .justify-center;
  @extend .column;

  color: $clr3;
  font-family: "Lilita-One-regular";
  padding-bottom: em(50px);
  font-size: em(30px);
  @include for-normal-layout {
  }
  @include for-narrow-layout {
  }
  @include for-mobile-layout {
    font-size: em(20px);
  }
}

.score-item {
  @extend .flex;
  @extend .align-center;
  @extend .space-between;

  height: em(40px);
  padding: em(5px);
  @include for-mobile-layout {
    padding-left: rem(25px);
    padding-right: rem(25px);
  }
  @include for-narrow-layout {
    padding-left: rem(35px);
    padding-right: rem(35px);
  }
  @include for-normal-layout {
    padding-left: rem(400px);
    padding-right: rem(400px);
  }
}

.highlight {
  background-color: $clr5;
}

.player-details {
  @extend .flex;
  @extend .align-center;

  height: 100%;
}

.player-place {
  margin-right: em(10px);
}

.player-avatar {
  height: 100%;
}
.player-avatar img {
  height: 100%;
  border-radius: em(300px);
  margin-right: em(10px);
}

.last-score {
  color: $correct;
  font-size: em(12px)
}

.score {
  @extend .flex;
  @extend .align-center;
}

.score-label {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  color: $clr3;
  font-family: "Lilita-One-regular";
  font-size: em(29px);
  margin: em(20px);
  p {
    font-size: em(16px);
    text-align: center;
    padding: 0;
    color: $clr2;
    margin: 0;
  }
  span {
    font-size: em(25px);
    padding: 0;
  }

  .center {
    text-align: center;
  }
}

.table-heading {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  
  color: $clr3;
}

.next-question {
  @extend .fixed-btn;
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;

  button {
    @extend .white-btn;
  }
}
