/* Breakpoint mixins */
@import '../../styles/config/functions';

$mobile-breakpoint: 460px;
$narrow-breakpoint: 830px;
$normal-breakpoint: 1000px;

@mixin for-mobile-layout {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin for-narrow-layout {
  @media (min-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin for-normal-layout {
  @media (min-width: $narrow-breakpoint) {
    @content;
  }
}

@mixin for-wide-layout {
  @media (min-width: $normal-breakpoint) {
    @content;
  }
}

.layout-container {
  @include for-mobile-layout {
    padding-left: rem(25px);
    padding-right: rem(25px);
  }
  @include for-narrow-layout {
    padding-left: rem(35px);
    padding-right: rem(35px);
  }
  @include for-normal-layout {
    padding-left: rem(120px);
    padding-right: rem(120px);
  }
}
