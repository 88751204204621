@font-face {
  font-family: "Titillium-Web";
  src: url('../../assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf');
  font-display: fallback;
}
@font-face {
  font-family: "Lilita-One-regular";
  src: url('../../assets/fonts/Lilita_One/LilitaOne-Regular.ttf');
  font-display: fallback;
}
