@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';
@import '../../styles/core//buttons';

.question-header {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  @extend .column;

  background-color: $clr3;

  font-family: "Lilita-One-regular", sans-serif;
  border-bottom-left-radius: em(70px);
  border-bottom-right-radius: em(70px);
  @include for-mobile-layout {
    padding-top: em(35px);
    padding-bottom: em(35px);
    padding-left: em(30px);
    padding-right: em(25px);
    font-size: em(20px);
    margin-bottom: em(50px);
  }
  @include for-normal-layout {
    padding-top: em(80px);
    padding-bottom: em(80px);
    padding-left: em(50px);
    padding-right: em(50px);
    font-size: em(40px);
    margin-bottom: em(60px);

  }
  @include for-narrow-layout {
    padding-top: em(40px);
    padding-bottom: em(40px);
    padding-left: em(50px);
    padding-right: em(50px);
    font-size: em(40px);
    margin-bottom: em(40px);
  }

  span {
    @include for-mobile-layout {
      padding-bottom: em(5px);
    }
  }

  .timer {
    color: $clr1;
  }
}

.bonus-bg {
  background-color: $clr2;
}

.question-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: em(20px);
  row-gap: em(50px);
  padding-right:em(10px);
  padding-left:em(10px);
}

.option-btn {
  padding: em(20px);
  cursor: pointer;
  font-family: "Lilita-One-regular", sans-serif;
  font-size:em(40px);
  background-color: $clr3;
  position: relative;
  border:none;
  box-sizing: border-box;
  border-radius: em(20px);
  outline: none;
  @include for-mobile-layout {
    font-size:em(20px);
  }
  @include for-normal-layout {
    font-size:em(40px);
  }
  @include for-narrow-layout {
    font-size:em(40px);
  }
}

.correct-option {
  border:5px solid $correct;
}

.wrong-option {
  border:5px solid $wrong;
}

.icon-span {
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;

  position: absolute;
  top:em(-20px);
  left:0;
  background-color: white;
  width: em(50px);
  height: em(50px);
  font-size: em(10px);
  border-radius: em(300px);
  border:5px solid black;
}

.correct-icon {
  border:5px solid $correct;
  i {
    color: $correct;
  }
}

.wrong-icon {
  border:5px solid $wrong;
  i{
    color: $wrong;
  }
}

.next-question {
  @extend .fixed-btn;
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;

  button {
    @extend .white-btn;

    @include for-mobile-layout {
      font-size:em(18px);
    }
    @include for-normal-layout {
      font-size:em(40px);
    }
    @include for-narrow-layout {
      font-size:em(40px);
    }

    border-color: $clr2;
    border-style: solid;

    &:focus {
      outline: none;
    }
  }
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
