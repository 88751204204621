@import '../../styles/config/variables';
@import '../../styles/config/layout';
@import '../../styles/config/functions';
@import '../../styles/core/typography';
@import '../../styles/core/helpers';

.app-header {
  @extend .flex;
  @extend .layout-container;
  @extend .justify-center;
  @extend .align-center;
  height: rem(53px);
  height: 7rem;
  background: $clr1;
  font-family: "Lilita-One-regular";

  a {
    color: $clr3;
    text-decoration-line: none;
  }

}

.header-btn {
  width: rem(39px);
  height: rem(39px);
  background-color: lightgrey;
  color: black;
  cursor: pointer;
  outline: none;
  border: 1px solid black;
}

.header-btn-container .header-btn:not(:first-child) {
  margin-left: rem(14px);
}
